import {useEffect, useState} from "react";
import {pathContextualCustom, TranslationPortalFile} from "../../utils/constants";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {session} from "../../api/ApiSession";
import {StatsSampleUrls} from "../../api/model/category/custom/KeywordsOverview";
import {CustomCategory} from "../../api/model/category/custom/CustomCategory";
import {CustomCategoryConfigStep} from "../../api/model/category/custom/CustomCategoryConfigStep";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {Category} from "../../api/model/category/Category";
import {DEFAULT_KEYWORD_THRESHOLD} from "../../api/model/category/custom/Threshold";
import * as XLSX from "xlsx";
import clsx from "clsx";
import {ConfigHeader, ProgressBar, TagKeyword} from "../../component/snippet";
import {Keyword} from "../../api/model/category/custom/Keyword";
import {getCategoryStepFromValue} from "../../api/model/category/CategoryStepSize";
import {Action, Box, BoxShadow, BoxSpacing, ButtonUpload, CircleStep, Icon, IconTooltip, LayoutColumns, LayoutRows, Loadable, MainContentPageHeader, SearchBar, ToggleOption, TranslationLibFile} from "@sirdata/ui-lib";
import {MainContent, Wrapper} from "../../common/component/widget";
import {MainHeader} from "../../common/component/snippet";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";

const QUERY_MIN_LENGTH = 2;
const SUGGESTED_KEYWORDS_COUNT = 20;
const SUGGESTED_CATEGORIES_COUNT = 5;
const URL_EXAMPLES_COUNT = 15;

function ContextualCustomCategoryKeywords() {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textConfig} = useTranslation(TranslationPortalFile.CONTEXTUAL_CUSTOM_CONFIGURATION);
    const navigate = useNavigate();
    const {id: customCategoryId} = useParams() as {id: string};
    const [isEdit, setEdit] = useState(true);
    const [isTouched, setTouched] = useState(false);

    const [customCategory, setCustomCategory] = useState<CustomCategory>(new CustomCategory());
    const [selectedKeywords, setSelectedKeywords] = useState<string[]>([]);
    const [undesiredSuggestedKeywords, setUndesiredSuggestedKeywords] = useState<string[]>([]);

    const [isLoadingImport, setLoadingImport] = useState(false);
    const [isLoadingKeywords, setLoadingKeywords] = useState(false);
    const [isLoadingRelatedContent, setLoadingRelatedContent] = useState(false);

    const [isLoadingKeywordsError, setLoadingKeywordsError] = useState(false);

    const [query, setQuery] = useState<string>("");
    const [isStrictQuery, setStrictQuery] = useState(false);
    const [suggestedKeywords, setSuggestedKeywords] = useState<string[]>([]);
    const [currentSuggestedKeywords, setCurrentSuggestedKeywords] = useState<string[]>([]);
    const [lastSuggestedKeyword, setLastSuggestedKeyword] = useState<string>();
    const [sampleUrls, setSampleUrls] = useState<StatsSampleUrls[]>([]);
    const [suggestedCategories, setSuggestedCategories] = useState<Category[]>([]);
    const [importMessage, setImportMessage] = useState<{ message?: string; isError?: boolean }>();

    useEffect(() => {
        (async function () {
            try {
                const newCustomCategory = await session.restCustomCategory.getCustomCategory(+customCategoryId);
                setCustomCategory(newCustomCategory);
                const selectedKeywords = newCustomCategory.keywords.map((keyword) => keyword.keyword);
                setSelectedKeywords(selectedKeywords);
                setUndesiredSuggestedKeywords(selectedKeywords);
                setEdit(newCustomCategory.active);
            } catch (e) {
                if (e instanceof ErrorResponse && e.statusCode === HttpStatusCode.NOT_FOUND) {
                    navigate(pathContextualCustom);
                }
            }
        })();
    }, [navigate, customCategoryId]);

    useEffect(() => {
        setLoadingKeywordsError(false);
        if (query.length > QUERY_MIN_LENGTH) {
            setLoadingKeywords(true);
            (async () => {
                try {
                    const keywordsResult = await session.restCustomCategory.getSuggestedKeywords(query, isStrictQuery);
                    setSuggestedKeywords(keywordsResult.keywords || []);

                    let currentSuggestedKeywords = (keywordsResult.keywords || []).filter((keyword) => !undesiredSuggestedKeywords.includes(keyword)).slice(0, SUGGESTED_KEYWORDS_COUNT);
                    setCurrentSuggestedKeywords(currentSuggestedKeywords);
                } catch (e) {
                    setLoadingKeywordsError(true);
                } finally {
                    setLoadingKeywords(false);
                }
            })();
        } else {
            setSuggestedKeywords([]);
            setCurrentSuggestedKeywords([]);
        }
    }, [query, isStrictQuery]); // eslint-disable-line

    useEffect(() => {
        if (selectedKeywords.length > 0) {
            setLoadingRelatedContent(true);
            (async () => {
                try {
                    const keywordsOverview = await session.restCustomCategory.getKeywordsOverview(selectedKeywords);
                    setSampleUrls(keywordsOverview.sample_urls.slice(0, URL_EXAMPLES_COUNT));

                    const categories = await session.restCustomCategory.getSuggestedCategories(selectedKeywords);
                    setSuggestedCategories(categories.slice(0, SUGGESTED_CATEGORIES_COUNT));
                } catch (e) {
                } finally {
                    setLoadingRelatedContent(false);
                }
            })();
        } else {
            setSampleUrls([]);
            setSuggestedCategories([]);
        }
    }, [selectedKeywords]);

    const handleSave = async () => {
        try {
            const newCustomCategory = new CustomCategory();
            newCustomCategory.load(customCategory);

            const threshold = customCategory.keywords?.length ? customCategory.keywords[0].threshold : DEFAULT_KEYWORD_THRESHOLD;
            newCustomCategory.keywords = selectedKeywords.map((k) => new Keyword(k, threshold));

            await session.restCustomCategory.updateCustomCategory(newCustomCategory);
            if (isEdit) setTouched(false);
        } catch (e) {
        }
    };

    const goToCategory = (category: Category) => {
        const newWindow = window.open(category.getRoute(), "_blank");
        if (newWindow) newWindow.focus();
    };

    const handleImportFile = (file?: File) => {
        setLoadingImport(true);
        try {
            if (file) {
                const reader = new FileReader();
                reader.onload = (evt) => {
                    const fileResult = evt.target?.result;
                    const workBook = XLSX.read(fileResult, {type: "binary"});
                    const workSheetName = workBook.SheetNames[0];
                    const workSheet = workBook.Sheets[workSheetName];
                    const dataString = XLSX.utils.sheet_to_csv(workSheet);

                    (async () => {
                        try {
                            const dataList = dataString.split(/\r\n|\n|,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/).filter((it) => it);
                            const keywordsStats = await session.restCustomCategory.getKeywordsStats(dataList);
                            const keywords = keywordsStats.filter(({keyword}) => keyword !== "" && !selectedKeywords.find((it) => it === keyword)).map(({keyword}) => keyword);
                            if (keywords.length) {
                                let newSelectedKeywords = [...selectedKeywords, ...keywords];
                                setSelectedKeywords(newSelectedKeywords);
                                setUndesiredSuggestedKeywords(newSelectedKeywords);
                                setTouched(true);
                                setImportMessage({message: textConfig("keywords.search.import.result", {count: keywords.length})});
                                setTimeout(() => setImportMessage({}), 5000);
                            }
                            setLoadingImport(false);
                        } catch (e) {
                        }
                    })();
                };
                reader.readAsBinaryString(file);
            }
        } catch (e) {
            setImportMessage({message: textConfig("keywords.search.import.error"), isError: true});
            setTimeout(() => setImportMessage({}), 5000);
            setLoadingImport(false);
        }
    };

    const toggleSelectedKeyword = (keyword: string) => {
        if (selectedKeywords.includes(keyword)) {
            setSelectedKeywords(selectedKeywords.filter((it) => it !== keyword));
        } else {
            setSelectedKeywords([...selectedKeywords, keyword]);
            setUndesiredSuggestedKeywords([...selectedKeywords, keyword]);
        }
        setTouched(true);

        // Replace keyword by another one
        const newKeyword = suggestedKeywords.find((it) => it !== keyword && !selectedKeywords.includes(it) && !currentSuggestedKeywords.includes(it)) || "";
        if (newKeyword) {
            const newCurrentSuggestedKeywords = currentSuggestedKeywords.map((it) => (it === keyword) ? newKeyword : it);
            setLastSuggestedKeyword(newKeyword);
            setCurrentSuggestedKeywords(newCurrentSuggestedKeywords);
        } else {
            setLastSuggestedKeyword("");
            setCurrentSuggestedKeywords(currentSuggestedKeywords.filter((it) => it !== keyword));
        }
    };

    return (
        <Wrapper>
            <MainHeader preventUnsaved={isTouched}/>
            <ConfigHeader
                step={CustomCategoryConfigStep.Keywords}
                edit={isEdit}
                touched={isTouched}
                disableSave={selectedKeywords.length === 0}
                onSave={handleSave}
                previousPath={pathContextualCustom}
                nextPath={customCategory.getRoute(CustomCategoryConfigStep.Relevancy)}
            />
            <MainContent>
                {!isEdit && <ProgressBar step={2}/>}
                <MainContentPageHeader
                    title={isEdit ? textConfig("keywords.title_edit") : textConfig("keywords.title_create")}
                    description={textConfig("keywords.description")}
                    icon={!isEdit ? <CircleStep step={2}/> : undefined}
                />
                <LayoutRows>
                    <LayoutColumns>
                        <div className="keywords-section-container">
                            <span className="h3 keywords-section-title">
                                {textConfig("keywords.search.title")}
                                <IconTooltip
                                    icon={{name: "help"}}
                                    text={textConfig("keywords.search.tooltip")}
                                    cssClass="keywords-section-tooltip"
                                />
                            </span>
                            <div className="keywords-section-subtitle">
                                <span>
                                    {textConfig("keywords.search.type_keyword.title")}
                                    <IconTooltip
                                        icon={{name: "help"}}
                                        text={textConfig("keywords.search.type_keyword.tooltip")}
                                        cssClass="keywords-section-tooltip"
                                    />
                                </span>
                            </div>
                            <Box cssClass="keywords-edition" shadow={BoxShadow.BLACK_MEDIUM} spacing={BoxSpacing.LARGE}>
                                <div className="keywords-edition-search">
                                    <SearchBar placeholder={textConfig("keywords.search.type_keyword.placeholder")} onChange={setQuery}/>
                                    <div className="keywords-search-mode">
                                        <span className="keywords-search-mode-title">
                                            {textConfig("keywords.search.type_keyword.query_type.title")}
                                            <IconTooltip
                                                icon={{name: "help"}}
                                                text={textConfig("keywords.search.type_keyword.query_type.tooltip")}
                                                cssClass="keywords-section-tooltip"
                                            />
                                        </span>
                                        <span className="keywords-search-mode-field">
                                            <ToggleOption
                                                firstOption={textConfig("keywords.search.type_keyword.query_type.basic")}
                                                secondOption={textConfig("keywords.search.type_keyword.query_type.advanced")}
                                                isFirstOption={isStrictQuery}
                                                onClick={() => setStrictQuery((prev) => !prev)}
                                                slim
                                            />
                                        </span>
                                    </div>
                                    <div className="keywords-search-suggestions">
                                        <Loadable loading={isLoadingKeywords}>
                                            {((query && currentSuggestedKeywords.length > 0) ?
                                                currentSuggestedKeywords.map((keyword) =>
                                                    <TagKeyword key={keyword} value={keyword} onClick={toggleSelectedKeyword} highlight={keyword === lastSuggestedKeyword}/>
                                                ) :
                                                <span className="keywords-section-text">{textConfig(isLoadingKeywordsError ? "keywords.search.type_keyword.error" : "keywords.search.type_keyword.text")}</span>
                                            )}
                                        </Loadable>
                                    </div>
                                </div>
                            </Box>
                            <div className="keywords-section-subtitle">
                                <span>{textConfig("keywords.search.import.title")}</span>
                            </div>
                            <Box cssClass="keywords-edition" shadow={BoxShadow.BLACK_MEDIUM} spacing={BoxSpacing.LARGE}>
                                <div className="keywords-edition-import">
                                    <ButtonUpload
                                        label={textConfig("keywords.search.import.button")}
                                        acceptType=".csv,.xlsx,.xls"
                                        onChange={handleImportFile}
                                        disabled={isLoadingImport}
                                    />
                                    <Loadable loading={isLoadingImport} loaderOptions={{small: true}}>
                                        <span className={clsx("keywords-settings-import-message", {"error": importMessage?.isError})}>{importMessage?.message}</span>
                                    </Loadable>
                                </div>
                            </Box>
                        </div>
                        <div className="keywords-section-container">
                            <span className="h3 keywords-section-title">
                                {textConfig("keywords.selection.title")} {selectedKeywords.length > 0 && `(${selectedKeywords.length})`}
                                <IconTooltip
                                    icon={{name: "help"}}
                                    text={textConfig("keywords.selection.tooltip")}
                                    cssClass="keywords-section-tooltip"
                                />
                            </span>
                            <Box cssClass="keywords-selection" shadow={BoxShadow.BLACK_MEDIUM} spacing={BoxSpacing.LARGE}>
                                {selectedKeywords.length > 0 ?
                                    <>
                                        <span className="keywords-section-button">
                                            <IconTooltip
                                                icon={{...Action.DELETE.icon, colorIcon: ""}}
                                                text={textCommon(Action.REMOVE_ALL.labelKey)}
                                                onClick={() => {
                                                    setSelectedKeywords([]);
                                                    setUndesiredSuggestedKeywords([]);
                                                }}
                                                cssClass="keywords-section-button__icon"
                                            />
                                        </span>
                                        <div className="keywords-selection-content">
                                            {selectedKeywords.map((keyword) =>
                                                <TagKeyword key={keyword} value={keyword} onClick={toggleSelectedKeyword} removable/>
                                            )}
                                        </div>
                                    </> :
                                    <span className="keywords-section-text">{textConfig("keywords.selection.text")}</span>
                                }
                            </Box>
                        </div>
                        <LayoutRows>
                            <div className="keywords-section-container">
                                <span className="h3 keywords-section-title">
                                    {textConfig("keywords.urls_examples.title")}
                                    <IconTooltip
                                        icon={{name: "help"}}
                                        text={textConfig("keywords.urls_examples.tooltip")}
                                        cssClass="keywords-section-tooltip"
                                    />
                                </span>
                                <Box cssClass={clsx("keywords-urls", {"keywords-urls-full": isEdit})} shadow={BoxShadow.BLACK_MEDIUM} spacing={BoxSpacing.LARGE}>
                                    <Loadable loading={isLoadingRelatedContent}>
                                        {sampleUrls.length > 0 ?
                                            <div className="keywords-urls-content">
                                                {sampleUrls.slice(0, isEdit ? URL_EXAMPLES_COUNT : 5).map((item) =>
                                                    <div key={item.url} className="keywords-url">
                                                        <Icon name="link"/>
                                                        <a href={item.url} target="_blank" rel="noopener noreferrer">{item.url}</a>
                                                    </div>
                                                )}
                                            </div> :
                                            <span className="keywords-section-text">{textConfig(selectedKeywords.length > 0 ? "keywords.urls_examples.no_results" : "keywords.urls_examples.text")}</span>
                                        }
                                    </Loadable>
                                </Box>
                            </div>
                            {!isEdit &&
                                <div className="keywords-section-container">
                                    <span className="h3 keywords-section-title">
                                        {textConfig("keywords.suggested_categories.title")}
                                        <IconTooltip
                                            icon={{name: "help"}}
                                            text={textConfig("keywords.suggested_categories.tooltip")}
                                            cssClass="keywords-section-tooltip"
                                        />
                                    </span>
                                    <Box cssClass="keywords-suggested-categories" shadow={BoxShadow.BLACK_MEDIUM} spacing={BoxSpacing.LARGE}>
                                        <Loadable loading={isLoadingRelatedContent}>
                                            {suggestedCategories.length > 0 ?
                                                <div className="suggested-categories-content">
                                                    {suggestedCategories.map((category) =>
                                                        <div key={category.id} className="suggested-category">
                                                            <div className="suggested-category-infos">
                                                                <span className="suggested-category-parent">{category.top_path_element?.name}</span>
                                                                <span className="suggested-category-name" onClick={() => goToCategory(category)}>{category.name}</span>
                                                            </div>
                                                            <div className="suggested-category-volume">
                                                                <Icon name="signal_cellular_alt" cssClass="suggested-category-volume-icon"/>
                                                                <span className="suggested-category-volume-value">{getCategoryStepFromValue(category.urls_count)}</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div> :
                                                <span className="keywords-section-text">{textConfig(selectedKeywords.length > 0 ? "keywords.suggested_categories.no_results" : "keywords.suggested_categories.text")}</span>
                                            }
                                        </Loadable>
                                    </Box>
                                </div>
                            }
                        </LayoutRows>
                    </LayoutColumns>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default ContextualCustomCategoryKeywords;
