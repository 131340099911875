import React, {useEffect, useState} from "react";
import {Action, Button, EmptyContentMessage, Loadable, SearchToolbar, TranslationLibFile} from "@sirdata/ui-lib";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

import SvgFavoritesFolder from "../../component/svg/SvgFavoriteFolder";
import {exportTaxonomyElements} from "../../utils/helper";
import {FolderFavorites} from "../../api/model/FolderFavorites";
import {ListTaxonomyElement, TaxonomySettingToolbar} from "../../component/snippet";
import {pathExplore, TranslationPortalFile} from "../../utils/constants";
import {TaxonomyElement} from "../../api/model/taxonomy/TaxonomyElement";
import {TaxonomyFilters} from "../../utils/taxonomy/TaxonomyFilters";

type FavoritesFolderListProps = {
    folder: FolderFavorites;
    isLoading: boolean;
}

const FavoritesFolderList: React.FC<FavoritesFolderListProps> = ({folder, isLoading}) => {
    const navigate = useNavigate();
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textFavorites} = useTranslation(TranslationPortalFile.FAVORITES);

    const [currentElements, setCurrentElements] = useState<TaxonomyElement[]>([]);
    const [filters, setFilters] = useState<TaxonomyFilters>(new TaxonomyFilters());
    const [query, setQuery] = useState<string>("");

    const [isExporting, setExporting] = useState(false);

    useEffect(() => {
        let currentElements = folder.elements || [];
        if (query) {
            currentElements = currentElements.filter((item) => {
                const name = item.audience_segment?.full_name || item.audience_category?.name || item.audience_curated_deal?.name || "";
                return name.toLowerCase().includes(query.toLowerCase());
            });
        }
        setCurrentElements(currentElements);
    }, [folder, query]);

    const handleExportCSV = () => {
        setExporting(true);
        setTimeout(() => {
            exportTaxonomyElements(`${textFavorites("title")}_${folder.name}`, folder.elements, filters);
            setExporting(false);
        }, 300);
    };

    const handleChangeFilters = (filters: TaxonomyFilters) => {
        setFilters(filters);
    };

    return(
        <>
            {!!folder.elements.length && <TaxonomySettingToolbar initialFilters={filters} onChange={handleChangeFilters}/>}
            <Loadable loading={isLoading} loaderOptions={{cssClass: "loader-full-page"}}>
                {!!folder.elements.length ?
                    <>
                        <SearchToolbar
                            searchBar={{placeholder: textFavorites("search_for_audiences"), value: query, onChange: setQuery}}
                            actions={[<Button key="button-export-csv" onClick={handleExportCSV} icon={Action.EXPORT.icon} loading={isExporting}>{textCommon(Action.EXPORT.labelKey)}</Button>]}
                        />
                        <div className="taxonomy-list">
                            <div className="taxonomy-list__elements">
                                {currentElements.map((item) =>
                                    <ListTaxonomyElement key={item.elementId} item={item} filters={filters}/>
                                )}
                            </div>
                        </div>
                    </> :
                    <EmptyContentMessage svg={SvgFavoritesFolder} message={textFavorites("message.folder.text")}>
                        <Button onClick={() => navigate(pathExplore)}>
                            {textFavorites("message.folder.browse_audiences")}
                        </Button>
                    </EmptyContentMessage>
                }
            </Loadable>
        </>
    );
};

export default FavoritesFolderList;
