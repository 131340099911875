import React, {ChangeEvent, useEffect, useState} from "react";
import {session} from "../../api/ApiSession";
import {FolderFavorites} from "../../api/model/FolderFavorites";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../utils/constants";
import {useNavigate} from "react-router-dom";
import {onKeyEnter} from "../../common/utils/form";
import SvgFavoritesIndex from "../../component/svg/SvgFavoritesIndex";
import {MainContent, Wrapper} from "../../common/component/widget";
import {
    Action,
    ActionsMenu,
    Box,
    BoxProps,
    Button,
    ButtonStyle,
    ContentBlock,
    EmptyContentMessage,
    FlexContent,
    FlexContentAlignment,
    FlexContentDirection,
    FlexContentSpacing,
    Icon,
    LayoutRows,
    Loadable,
    MainContentPageHeader,
    ModalConfirmMessage,
    SearchToolbar,
    TranslationLibFile
} from "@sirdata/ui-lib";
import {MainHeader} from "../../common/component/snippet";

function FavoritesIndex() {
    const navigate = useNavigate();
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textFavorites} = useTranslation(TranslationPortalFile.FAVORITES);

    const [folders, setFolders] = useState<FolderFavorites[]>([]);
    const [isShowNewFolder, setShowNewFolder] = useState<boolean>();
    const [newFolderName, setNewFolderName] = useState<string>("");
    const [activeRenameFolder, setActiveRenameFolder] = useState<FolderFavorites>();
    const [activeDeleteFolder, setActiveDeleteFolder] = useState<FolderFavorites>();
    const [isLoading, setLoading] = useState(true);

    const doCreateFolder = async () => {
        try {
            let newFolder = new FolderFavorites();
            newFolder.name = newFolderName;
            await session.restFavorite.createFolder(newFolder);
            setFolders(await session.loadFavoriteFolders());
            setShowNewFolder(false);
            setNewFolderName("");
        } catch (e) {
            console.error("Failed to create folder", e);
        }
    };

    const onRenameFolder = (folder: FolderFavorites) => {
        setActiveRenameFolder(folder);
    };

    const onChangeRenameFolder = ({target}: ChangeEvent<HTMLInputElement>) => {
        setActiveRenameFolder((prev) => new FolderFavorites({...prev, name: target.value}));
    };

    const doRenameFolder = async () => {
        if (!activeRenameFolder) return;

        try {
            let folder = new FolderFavorites(activeRenameFolder);
            await session.restFavorite.updateFolder(folder);
            setFolders(await session.loadFavoriteFolders());
            setActiveRenameFolder(undefined);
        } catch (e) {
            console.error("Failed to rename folder", e);
        }
    };

    const onDuplicateFolder = (folder: FolderFavorites) => {
        (async function () {
            try {
                let newFolder = new FolderFavorites({...folder, name: `${folder.name} (copy)`});
                await session.restFavorite.copyFolder(newFolder);
                setFolders(await session.loadFavoriteFolders());
            } catch (e) {
                console.error("Failed to duplicate folder", e);
            }
        })();
    };

    const onDeleteFolder = (folder: FolderFavorites) => {
        setActiveDeleteFolder(folder);
    };

    const doDeleteFolder = async (folder: FolderFavorites) => {
        if (!activeDeleteFolder) return;
        try {
            await session.restFavorite.deleteFolder(folder.id);
            setFolders(await session.loadFavoriteFolders());
            setActiveDeleteFolder(undefined);
        } catch (e) {
            console.error("Failed to delete folder", e);
        }
    };

    useEffect(() => {
        (async function () {
            try {
                const folders = await session.getFavoriteFolders();
                setFolders(folders);
            } catch (e) {
                console.error("Failed to load favorite list", e);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent>
                <MainContentPageHeader title={textFavorites("title")} icon={{name: "favorite"}}/>
                <LayoutRows>
                    <Loadable loading={isLoading} loaderOptions={{cssClass: "loader-full-page"}}>
                        {folders.length > 0 || isShowNewFolder ?
                            <ContentBlock>
                                <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.LARGE_PLUS}>
                                    <SearchToolbar
                                        actions={[
                                            <Button
                                                key={"create-folder-button"}
                                                onClick={() => setShowNewFolder(true)}
                                                icon={{name: "add_circle"}}
                                            >
                                                {textFavorites("form.create_folder")}
                                            </Button>
                                        ]}
                                    />
                                    <div className="grid-cards">
                                        {isShowNewFolder &&
                                            <Box
                                                cssClass={"grid-card active"}
                                                {...BoxProps.SECTION_BLOCK_WITH_SHADOW}
                                            >
                                                <FlexContent
                                                    direction={FlexContentDirection.COLUMN}
                                                    alignment={FlexContentAlignment.CENTER}
                                                    spacing={FlexContentSpacing.MEDIUM}
                                                >
                                                    <Icon name="folder" cssClass="grid-card__icon"/>
                                                    <input
                                                        type="text"
                                                        className="grid-card__input"
                                                        value={newFolderName}
                                                        placeholder={textFavorites("form.folder_name")}
                                                        onChange={(e) => setNewFolderName(e.target.value)}
                                                        onKeyDown={(e) => onKeyEnter(e, doCreateFolder)}
                                                        autoFocus
                                                    />
                                                </FlexContent>
                                            </Box>
                                        }
                                        {folders.map((folder) =>
                                            <Box
                                                key={folder.id}
                                                cssClass={"grid-card"}
                                                onClick={() => navigate(folder.getRoute())}
                                                {...BoxProps.SECTION_BLOCK_WITH_SHADOW}
                                            >
                                                <FlexContent
                                                    direction={FlexContentDirection.COLUMN}
                                                    alignment={FlexContentAlignment.CENTER}
                                                    spacing={FlexContentSpacing.MEDIUM}
                                                >
                                                    <Icon name="folder" cssClass="grid-card__icon"/>
                                                    {activeRenameFolder?.id === folder.id ?
                                                        <input
                                                            type="text"
                                                            className="grid-card__input"
                                                            value={activeRenameFolder.name}
                                                            placeholder={textFavorites("form.folder_name")}
                                                            onChange={onChangeRenameFolder}
                                                            onKeyDown={(e) => onKeyEnter(e, doRenameFolder)}
                                                            onClick={(e) => e?.stopPropagation()}
                                                            autoFocus
                                                        /> :
                                                        <span className="grid-card__name">{folder.name}</span>
                                                    }
                                                </FlexContent>
                                                <div className="grid-card__menu" onClick={(e) => e.stopPropagation()}>
                                                    <ActionsMenu
                                                        iconTooltip={{icon: Action.MORE_ACTIONS.icon, text: textCommon(Action.MORE_ACTIONS.labelKey)}}
                                                        items={[
                                                            {label: textFavorites("actions.rename"), onClick: () => onRenameFolder(folder)},
                                                            {label: textCommon(Action.DUPLICATE.labelKey), onClick: () => onDuplicateFolder(folder)},
                                                            {label: textCommon(Action.DELETE.labelKey), onClick: () => onDeleteFolder(folder), critical: true, separator: true}
                                                        ]}
                                                    />
                                                </div>
                                            </Box>
                                        )}
                                    </div>
                                </FlexContent>
                            </ContentBlock> :
                            <EmptyContentMessage
                                svg={SvgFavoritesIndex}
                                message={textFavorites("message.index.text")}
                            >
                                <Button onClick={() => setShowNewFolder(true)}>
                                    {textFavorites("message.index.create_folder")}
                                </Button>
                            </EmptyContentMessage>
                        }
                    </Loadable>
                </LayoutRows>
                <ModalConfirmMessage
                    message={textFavorites("actions.delete_warning")}
                    confirm={{style: ButtonStyle.DEFAULT_MIDNIGHT, onClick: () => doDeleteFolder(activeDeleteFolder!), children: textCommon(Action.DELETE.labelKey)}}
                    cancel={{style: ButtonStyle.PRIMARY_MIDNIGHT, onClick: () => setActiveDeleteFolder(undefined)}}
                    active={!!activeDeleteFolder}
                />
            </MainContent>
        </Wrapper>
    );
}

export default FavoritesIndex;
