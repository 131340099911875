import clsx from "clsx";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {Action, Icon} from "@sirdata/ui-lib";
import {CustomCategoryConfigStep} from "../../../api/model/category/custom/CustomCategoryConfigStep";
import {pathContextualCustomCategoryKeywords, pathContextualCustomCategoryRelevancy, TranslationPortalFile} from "../../../utils/constants";
import {PathHelper} from "../../../common/utils/PathHelper";

type ConfigHeaderMenuProps = {
    step: CustomCategoryConfigStep;
    onChangePath: (path: string) => void;
}

const ConfigHeaderMenu: FunctionComponent<ConfigHeaderMenuProps> = ({step, onChangePath}) => {
    const {t: textConfiguration} = useTranslation(TranslationPortalFile.CONTEXTUAL_CUSTOM_CONFIGURATION);
    const {id: customCategoryId} = useParams() as {id: string};

    const getTitleKey = () => {
        switch (step) {
            case CustomCategoryConfigStep.Keywords:
                return "burger_menu.edit_step_2";
            case CustomCategoryConfigStep.Relevancy:
                return "burger_menu.edit_step_3";
            default:
                return "";
        }
    };

    return (
        <div className="config-header__menu">
            <span className="config-header__menu__label">
                <Icon name="list"/>
                <span>{textConfiguration(getTitleKey())}</span>
                <Icon {...Action.EXPAND.icon}/>
            </span>
            <div className="config-header__menu__content">
                <ul>
                    <li className={clsx("config-header__menu__content__row", {"config-header__menu__content__row--active": step === CustomCategoryConfigStep.Keywords})}>
                        <span className="config-header__menu__content__row__link" onClick={() => onChangePath(PathHelper.buildPathWithId(pathContextualCustomCategoryKeywords, customCategoryId))}>{textConfiguration("burger_menu.edit_step_2")}</span>
                    </li>
                    <li className={clsx("config-header__menu__content__row", {"config-header__menu__content__row--active": step === CustomCategoryConfigStep.Relevancy})}>
                        <span className="config-header__menu__content__row__link" onClick={() => onChangePath(PathHelper.buildPathWithId(pathContextualCustomCategoryRelevancy, customCategoryId))}>{textConfiguration("burger_menu.edit_step_3")}</span>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default ConfigHeaderMenu;
