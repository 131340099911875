import React, {FunctionComponent, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {animateScroll as scroll} from "react-scroll";
import {pathContextualCustom, TranslationPortalFile} from "../../../utils/constants";
import {Action, Button, ButtonBack, ButtonLink, ButtonSize, ButtonStyle, FormLayoutButtons, FormLayoutRows, MainHeaderSubLayout, ModalContent, ModalDescription, ModalDescriptionAlignment, ModalNew, SplitButton, TranslationLibFile} from "@sirdata/ui-lib";
import {CustomCategoryConfigStep} from "../../../api/model/category/custom/CustomCategoryConfigStep";
import ConfigHeaderMenu from "./ConfigHeaderMenu";

type ConfigHeaderCreateProps = {
    step: CustomCategoryConfigStep;
    touched: boolean;
    last?: boolean;
    disableSave?: boolean;
    onSave: (finish?: boolean) => void;
    previousPath: string;
    nextPath: string;
}

const ConfigHeaderCreate: FunctionComponent<ConfigHeaderCreateProps> = ({step, touched, last, disableSave, onSave, previousPath, nextPath}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textConfig} = useTranslation(TranslationPortalFile.CONTEXTUAL_CUSTOM_CONFIGURATION);
    const navigate = useNavigate();

    const [showWarning, setShowWarning] = useState(false);
    const [unsafe, setUnsafe] = useState(touched);
    const [goToPath, setGoToPath] = useState<string | undefined>();

    const goTo = (path: string) => {
        scroll.scrollToTop({delay: 0, duration: 0, smooth: "none"});
        navigate(path);
    };

    const saveAndGoTo = async (path: string) => {
        await onSave(last);
        goTo(path);
    };

    const quitAndGoTo = (path: string) => {
        if (unsafe) {
            setGoToPath(path);
            setShowWarning(true);
        } else {
            goTo(path);
        }
    };

    useEffect(() => {
        setUnsafe(touched);
    }, [touched]);

    return (
        <MainHeaderSubLayout cssClass="config-header">
            <ConfigHeaderMenu step={step} onChangePath={quitAndGoTo}/>
            <div className="config-header__buttons">
                {disableSave ?
                    <ButtonBack onClick={() => quitAndGoTo(previousPath)} noIcon/>
                    :
                    <>
                        {last ?
                            <SplitButton
                                size={ButtonSize.MEDIUM}
                                actions={[
                                    {label: textConfig("header.buttons.finish"), onClick: () => saveAndGoTo(nextPath)},
                                    {label: textCommon(Action.BACK.labelKey), onClick: () => quitAndGoTo(previousPath)}
                                ]}
                            /> :
                            <SplitButton
                                size={ButtonSize.MEDIUM}
                                actions={[
                                    {label: textConfig("header.buttons.save_and_continue"), onClick: () => saveAndGoTo(nextPath)},
                                    {label: textConfig("header.buttons.save_and_finish_later"), onClick: () => saveAndGoTo(pathContextualCustom)},
                                    {label: textCommon(Action.BACK.labelKey), onClick: () => quitAndGoTo(previousPath)}
                                ]}
                            />
                        }
                        <div className="inline-buttons">
                            {!last && <ButtonLink onClick={() => saveAndGoTo(pathContextualCustom)}>{textConfig("header.buttons.save_and_finish_later")}</ButtonLink>}
                            <ButtonBack onClick={() => quitAndGoTo(previousPath)} noIcon/>
                            {last ?
                                <Button size={ButtonSize.MEDIUM} onClick={() => saveAndGoTo(nextPath)}>{textConfig("header.buttons.finish")}</Button> :
                                <Button size={ButtonSize.MEDIUM} onClick={() => saveAndGoTo(nextPath)}>{textConfig("header.buttons.save_and_continue")}</Button>
                            }
                        </div>
                    </>
                }
            </div>
            <ModalNew onClose={() => setShowWarning(false)} active={showWarning}>
                <ModalContent>
                    <FormLayoutRows>
                        <ModalDescription alignment={ModalDescriptionAlignment.CENTER}>
                            <span dangerouslySetInnerHTML={{__html: textConfig("header.warning_create")}}/>
                        </ModalDescription>
                        <FormLayoutButtons>
                            <Button size={ButtonSize.BIG} style={ButtonStyle.DEFAULT_MIDNIGHT} onClick={() => goTo(goToPath || previousPath)}>{textConfig("header.buttons.no")}</Button>
                            <Button size={ButtonSize.BIG} style={ButtonStyle.PRIMARY_MIDNIGHT} onClick={() => saveAndGoTo(goToPath || previousPath)}>{textConfig("header.buttons.yes")}</Button>
                        </FormLayoutButtons>
                    </FormLayoutRows>
                </ModalContent>
            </ModalNew>
        </MainHeaderSubLayout>
    );
};

export default ConfigHeaderCreate;
