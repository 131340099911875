import {PortalConfig} from "../common/api/PortalConfig";
import {Origin} from "../common/api/model/Origin";
import {pathDataSelection, pathExplore, pathFavorites, pathSearch} from "./constants";
import {pathHome} from "../common/utils/constants";
import {MENU} from "./Menu";

export const PORTAL = new PortalConfig(Origin.AUDIENCE, pathHome)
    .withMenuItems(MENU)
    .withSearchOptions({
        titleTranslationKey: "menu.search.search_for_something",
        path: pathSearch,
        alternateLink: {
            icon: "explore",
            path: pathExplore,
            label: "menu.search.explore"
        }
    }).withShortcuts([
        {target: pathDataSelection, icon: {name: "hotel_class"}, label: "menu.data_selection"},
        {target: pathFavorites, icon: {name: "favorite"}, label: "menu.favorites"}
    ])
;
