import React from "react";
import {getTaxonomyPlatform, TaxonomyPlatform} from "../../../api/model/taxonomy/TaxonomyPlatform";

const TagEuid: React.FC = () => {
    return (
        <span className="tag-euid">
            <img src="/images/logo_euid.png" alt={getTaxonomyPlatform(TaxonomyPlatform.THE_TRADE_DESK_EUID).name}/>
        </span>
    );
};

export default TagEuid;
