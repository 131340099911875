import React from "react";
import {useTranslation} from "react-i18next";
import {Icon} from "@sirdata/ui-lib";
import {TranslationPortalFile} from "../../../utils/constants";
import {UIEventManager} from "../../../common/utils/UIEventManager";
import {ModalCampaignBriefUiEvent} from "../../modal/campaign-brief/ModalCampaignBrief";

const ButtonCampaignBrief = () => {
    const {t: textCampaignBrief} = useTranslation(TranslationPortalFile.CAMPAIGN_BRIEF);

    return (
        <>
            <button
                type="button"
                className="btn-campaign-brief"
                onClick={() => UIEventManager.emit(ModalCampaignBriefUiEvent)}
            >
                <span>
                    <Icon name="forum" outlined/>
                    <span className="btn-campaign-brief-text">{textCampaignBrief("actions.ask_for_brief")}</span>
                </span>
            </button>
        </>
    );
};

export default ButtonCampaignBrief;
