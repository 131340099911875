import React, {FunctionComponent, Suspense, useEffect, useState} from "react";
import {BrowserRouter, Navigate, Outlet, Route, Routes} from "react-router-dom";
import {ScrollToTop} from "@sirdata/ui-lib";

import PrivateRoute from "./component/guard/PrivateRoute";
import ModalCampaignBrief from "./component/modal/campaign-brief/ModalCampaignBrief";
import ModalCuratedDealRequest from "./component/modal/ModalCuratedDealRequest";
import {
    Access,
    ContextualCategory,
    ContextualCustom,
    ContextualCustomCategory,
    ContextualCustomCategoryKeywords,
    ContextualCustomCategoryRelevancy,
    DataSelections,
    DataSelectionsDetails,
    Deal,
    Explore,
    FavoritesFolder,
    FavoritesFolderPublic,
    FavoritesIndex,
    Home,
    LegalNotice,
    Login,
    Partners,
    Search,
    Sso,
    UserSegment
} from "./screen";
import {Authorization} from "./api/model/account/Authorization";
import {Error403, Error404, Error500} from "./common/screen/index";
import {ApiEventListener} from "./common/component/snippet";
import {pathAccess, pathError403, pathError404, pathError500, pathHome, pathLegalNotice, pathLogin, pathSso} from "./common/utils/constants";
import {
    pathContextualCategory,
    pathContextualCustom,
    pathContextualCustomCategory,
    pathContextualCustomCategoryKeywords,
    pathContextualCustomCategoryRelevancy,
    pathDataSelection,
    pathDataSelectionWithPath,
    pathDeal,
    pathExplore,
    pathFavorites,
    pathFavoritesFolder,
    pathFavoritesFolderPublic,
    pathPartners,
    pathSearch,
    pathUserSegment
} from "./utils/constants";
import {PortalContextProvider} from "./common/context/PortalContext";
import {PortalSetting} from "./common/api/model/portal-setting/PortalSetting";
import {session} from "./api/ApiSession";

const App: FunctionComponent = () => {
    const [portalSetting, setPortalSetting] = useState<PortalSetting>(new PortalSetting());

    useEffect(() => {
        (async () => {
            try {
                setPortalSetting(await session.getPortalSetting());
            } catch (e) {
            }
        })();
    }, []);

    return (
        <React.StrictMode>
            <Suspense fallback={<></>}>
                <PortalContextProvider portalSetting={portalSetting}>
                    <BrowserRouter>
                        <Routes>
                            <Route element={<RouterMainLayout/>}>
                                <Route path={pathSso} element={<Sso/>}/>
                                <Route path={pathAccess} element={<Access/>}/>
                                <Route path={pathError403} element={<Error403/>}/>
                                <Route path={pathError404} element={<Error404/>}/>
                                <Route path={pathError500} element={<Error500/>}/>

                                <Route path="/" element={<Navigate to={pathLogin}/>}/>
                                <Route path={pathLogin} element={<Login/>}/>
                                <Route path={pathHome} element={<PrivateRoute children={<Home/>}/>}/>
                                <Route path={pathExplore} element={<PrivateRoute children={<Explore/>}/>}/>
                                <Route path={pathSearch} element={<PrivateRoute children={<Search/>}/>}/>
                                <Route path={pathContextualCategory} element={<PrivateRoute children={<ContextualCategory/>}/>}/>
                                <Route
                                    path={pathContextualCustomCategoryKeywords}
                                    element={
                                        <PrivateRoute
                                            children={<ContextualCustomCategoryKeywords/>}
                                            requiredAuthorization={Authorization.CUSTOM}
                                        />
                                    }
                                />
                                <Route
                                    path={pathContextualCustomCategoryRelevancy}
                                    element={
                                        <PrivateRoute
                                            children={<ContextualCustomCategoryRelevancy/>}
                                            requiredAuthorization={Authorization.CUSTOM}
                                        />
                                    }
                                />
                                <Route
                                    path={pathContextualCustom}
                                    element={
                                        <PrivateRoute
                                            children={<ContextualCustom/>}
                                            requiredAuthorization={Authorization.CUSTOM}
                                        />
                                    }
                                />
                                <Route
                                    path={pathContextualCustomCategory}
                                    element={
                                        <PrivateRoute
                                            children={<ContextualCustomCategory/>}
                                            requiredAuthorization={Authorization.CUSTOM}
                                        />
                                    }
                                />
                                <Route path={pathDataSelection} element={<PrivateRoute children={<DataSelections/>}/>}/>
                                <Route path={pathDataSelectionWithPath} element={<PrivateRoute children={<DataSelectionsDetails/>}/>}/>
                                <Route path={pathDeal} element={<PrivateRoute children={<Deal/>}/>}/>
                                <Route path={pathPartners} element={<PrivateRoute children={<Partners/>}/>}/>
                                <Route path={pathFavorites} element={<PrivateRoute children={<FavoritesIndex/>}/>}/>
                                <Route path={pathFavoritesFolder} element={<PrivateRoute children={<FavoritesFolder/>}/>}/>
                                <Route path={pathFavoritesFolderPublic} element={<PrivateRoute children={<FavoritesFolderPublic/>}/>}/>
                                <Route path={pathUserSegment} element={<PrivateRoute children={<UserSegment/>}/>}/>

                                <Route path={pathLegalNotice} element={<LegalNotice/>}/>

                                <Route path="*" element={<Error404/>}/>
                            </Route>
                        </Routes>
                    </BrowserRouter>
                </PortalContextProvider>
            </Suspense>
        </React.StrictMode>
    );
};

export default App;

function RouterMainLayout() {
    return (
        <>
            <ApiEventListener/>
            <ScrollToTop/>
            <Outlet/>
            <ModalCampaignBrief/>
            <ModalCuratedDealRequest/>
        </>
    );
}
