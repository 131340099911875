import {EmptyContentMessage, MainContentStyle, SvgError403} from "@sirdata/ui-lib";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

import {MainContent, Wrapper} from "../component/widget";
import {MainHeader} from "../component/snippet";
import {pathLogin, TranslationCommonFile} from "../utils/constants";
import {session} from "../../api/ApiSession";

function Error403() {
    const {t: textErrors} = useTranslation(TranslationCommonFile.ERRORS);
    const navigate = useNavigate();

    useEffect(() => {
        (async function () {
            try {
                await session.loadAccount();
            } catch {
                navigate(pathLogin);
            }
        })();
    }, [navigate]);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent style={MainContentStyle.NARROW_WIDTH}>
                <EmptyContentMessage svg={SvgError403} message={textErrors("error_403.text")}/>
            </MainContent>
        </Wrapper>
    );
}

export default Error403;
