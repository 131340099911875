import {useEffect, useState} from "react";
import {Alert, AlertSeverity, Box, BoxRadius, BoxSpacing, Button, ButtonSize, ButtonStyle, FlexContent, FlexContentAlignment, FlexContentDirection, FlexContentMobileDirection, FlexContentSpacing, HeroHeader, LayoutRows, Loadable, MainContentStyle, SvgRocket, ToggleSwitch} from "@sirdata/ui-lib";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import ModalConfirmAccess from "../component/modal/ModalConfirmAccess";
import {MainContent, Wrapper} from "../common/component/widget";
import {pathLogin, sirdataDataPrivacyPolicyUrl} from "../common/utils/constants";
import {PORTAL} from "../utils/Portal";
import {session} from "../api/ApiSession";
import {TranslationPortalFile} from "../utils/constants";
import {MainHeader} from "../common/component/snippet";

type AccessForm = {
    privacyPolicy: boolean;
}

function Access() {
    const {t: textAccess} = useTranslation(TranslationPortalFile.ACCESS);
    const navigate = useNavigate();

    const [isLoading, setLoading] = useState(false);
    const [accessForm, setAccessForm] = useState<AccessForm>({privacyPolicy: false});
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [isShowModalConfirmAccess, setShowModalConfirmAccess] = useState(false);

    useEffect(() => {
        (async function () {
            try {
                const account = await session.getAccount();
                if (account.hasAccess(PORTAL.origin.name)) {
                    navigate(PORTAL.defaultPath);
                }
            } catch {
                navigate(pathLogin);
            }
        })();
    }, [navigate]);

    const handleActivate = () => {
        (async () => {
            try {
                setLoading(true);
                await session.restAccount.activateService();
                await session.loadAccount();
                setTimeout(() => {
                    setLoading(false);
                    setShowModalConfirmAccess(true);
                }, 2000);
            } catch (e) {
                setErrorMessage(textAccess("error.unauthorized"));
            }
        })();
    };

    const isFormValidated = () => {
        return accessForm.privacyPolicy;
    };

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent style={MainContentStyle.FULL_WIDTH}>
                <LayoutRows>
                    <HeroHeader title={textAccess("title")}/>
                    <Loadable loading={isLoading}>
                        <FlexContent direction={FlexContentDirection.ROW} mobileDirection={FlexContentMobileDirection.COLUMN_REVERSE} spacing={FlexContentSpacing.MEDIUM} cssClass={"activation-section"}>
                            <SvgRocket/>
                            <Box radius={BoxRadius.MD} spacing={BoxSpacing.LARGE} cssClass="activation-card">
                                <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.LARGE}>
                                    <span className="h1 activation-card__title" dangerouslySetInnerHTML={{__html: textAccess("text")}}/>
                                    <FlexContent direction={FlexContentDirection.ROW}>
                                        <ToggleSwitch
                                            checked={accessForm.privacyPolicy}
                                            onChange={(value) => setAccessForm((prevState) => ({...prevState, privacyPolicy: value}))}
                                        />
                                        <span className="activation-card__terms" dangerouslySetInnerHTML={{__html: textAccess("items.privacy_policy", {privacyPolicyUrl: sirdataDataPrivacyPolicyUrl})}}/>
                                    </FlexContent>
                                    <FlexContent direction={FlexContentDirection.COLUMN} alignment={FlexContentAlignment.END}>
                                        <Button size={ButtonSize.BIG} style={ButtonStyle.PRIMARY_GREEN} onClick={handleActivate} disabled={!isFormValidated()}>
                                            {textAccess("activate_product")}
                                        </Button>
                                    </FlexContent>
                                    {errorMessage &&
                                        <Alert text={errorMessage} severity={AlertSeverity.DANGER}/>
                                    }
                                </FlexContent>
                            </Box>
                        </FlexContent>
                    </Loadable>
                </LayoutRows>
                <ModalConfirmAccess active={isShowModalConfirmAccess} onStart={() => navigate(PORTAL.defaultPath)}/>
            </MainContent>
        </Wrapper>
    );
}

export default Access;
