import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {AlertSeverity, ContentBlock, LayoutRows, MainContentPageHeader} from "@sirdata/ui-lib";

import FavoritesFolderList from "./FavoritesFolderList";
import {session} from "../../api/ApiSession";
import {FolderFavorites} from "../../api/model/FolderFavorites";
import {pathFavorites, TranslationPortalFile} from "../../utils/constants";
import {FavoriteFolderShareButton} from "../../component/snippet/index";
import {SirdataApiEvent} from "../../common/api/CommonApiClient";
import {MainContent, Wrapper} from "../../common/component/widget";
import {MainHeader} from "../../common/component/snippet";
import {UIEventManager} from "../../common/utils/UIEventManager";

function FavoritesFolder() {
    const navigate = useNavigate();
    const {t: textFavorites} = useTranslation(TranslationPortalFile.FAVORITES);
    const {id: folderId} = useParams() as {id: string};

    const [folder, setFolder] = useState<FolderFavorites>(new FolderFavorites());
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        (async function () {
            try {
                const folder = await session.restFavorite.getFolder(+folderId);
                if (folder) {
                    setFolder(folder);
                } else {
                    session.emit(SirdataApiEvent.eventNotFound);
                }
            } catch (e) {
                UIEventManager.alert(textFavorites("message.folder.get_folder_error"), AlertSeverity.DANGER);
            } finally {
                setLoading(false);
            }
        })();
    }, [folderId, textFavorites]);

    const updateFolder = async () => {
        try {
            let newFolder = new FolderFavorites({...folder, public: !folder.public});
            setFolder(newFolder);
            await session.restFavorite.updateFolder(newFolder);
        } catch (e) {
            UIEventManager.alert(textFavorites("message.folder.share_error"), AlertSeverity.DANGER);
        }
    };

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent>
                <MainContentPageHeader
                    icon={{name: "favorite"}}
                    title={{
                        label: folder.name,
                        parent: {
                            label: textFavorites("title"),
                            onClick: () => navigate(pathFavorites)
                        }
                    }}
                />
                <LayoutRows>
                    <ContentBlock>
                        {(!isLoading && !!folder.elements.length) && <FavoriteFolderShareButton folder={folder} onChange={updateFolder}/>}
                        <FavoritesFolderList folder={folder} isLoading={isLoading}/>
                    </ContentBlock>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default FavoritesFolder;
